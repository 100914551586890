import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosConfig from '../../utils/axiosConfig';

export const getAllAccessRecords = createAsyncThunk('get/access', async (_, { rejectWithValue }) => {
	try {
		const response = await axiosConfig.get('api/access/');
		return await response.data;
	} catch (error) {
		return rejectWithValue({ error: error?.response?.data?.error, message: error?.response?.data?.message || error?.error?.message });
	}
});
export const getAllAccessData = createAsyncThunk('get/access', async (_, { rejectWithValue }) => {
	try {
		const response = await axiosConfig.get('api/access/modules');
		return await response.data;
	} catch (error) {
		return rejectWithValue({ error: error?.response?.data?.error, message: error?.response?.data?.message || error?.error?.message });
	}
});

export const StoreAccessInfo = createAsyncThunk('add/access', async ({ formVal }, { rejectWithValue }) => {
	try {		
		const response = await axiosConfig.post('api/access', formVal);
		return await response.data;
	} catch (error) {
		return rejectWithValue({ error: error?.response?.data?.error, message: error?.response?.data?.message || error?.error?.message });
	}
});

export const getAccessInfo = createAsyncThunk('access_info', async ({ access_id }, { rejectWithValue }) => {
	try {
		const response = await axiosConfig.get('api/access/'+access_id);
		return await response.data;
	} catch (error) {
		return rejectWithValue({ error: error?.response?.data?.error, message: error?.response?.data?.message || error?.error?.message });
	}
});

export const ModifyAccessInfo = createAsyncThunk('modify/access', async ({ formVal, accessId }, { rejectWithValue }) => {
	try {
		const response = await axiosConfig.patch('api/access/', formVal);
		return await response.data;
	} catch (error) {
		return rejectWithValue({ error: error?.response?.data?.error, message: error?.response?.data?.message || error?.error?.message });
	}
});

export const DelAccessInfo = createAsyncThunk('del/access', async ({ accessId }, { rejectWithValue }) => {
	try {
		const response = await axiosConfig.delete('api/access/delete/'+accessId);
		return await response.data;
	} catch (error) {
		return rejectWithValue({ error: error?.response?.data?.error, message: error?.response?.data?.message || error?.error?.message });
	}
});