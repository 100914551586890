import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosConfig from '../../utils/axiosConfig';

export const getAllCatalogueData = createAsyncThunk('get/catalogueList', async (_, { rejectWithValue }) => {
	try {
		const response = await axiosConfig.get('api/catalogue');
		return await response.data;
	} catch (error) {
		return rejectWithValue({ error: error?.response?.data?.error, message: error?.response?.data?.message || error?.error?.message });
	}
});

export const StoreCatInfo = createAsyncThunk('add/catalogue', async ({ formVal }, { rejectWithValue }) => {
	try {
		const response = await axiosConfig.post('api/catalogue', formVal);
		return await response.data;
	} catch (error) {
		return rejectWithValue({ error: error?.response?.data?.error, message: error?.response?.data?.message || error?.error?.message });
	}
});

export const getCatalogueInfo = createAsyncThunk('cat_info', async ({ catId }, { rejectWithValue }) => {
	try {
		const response = await axiosConfig.get('api/catalogue/'+catId);
		return await response.data;
	} catch (error) {
		return rejectWithValue({ error: error?.response?.data?.error, message: error?.response?.data?.message || error?.error?.message });
	}
});

export const ModifyCatalogueInfo = createAsyncThunk('modify/cat', async ({ formVal,catId }, { rejectWithValue }) => {
	try {
		const response = await axiosConfig.patch('api/catalogue/'+catId, formVal);
		return await response.data;
	} catch (error) {
		return rejectWithValue({ error: error?.response?.data?.error, message: error?.response?.data?.message || error?.error?.message });
	}
});

export const DelCateloagueInfo = createAsyncThunk('del/cat', async ({ catId }, { rejectWithValue }) => {
	try {
		const response = await axiosConfig.delete('api/catalogue/'+catId);
		return await response.data;
	} catch (error) {
		return rejectWithValue({ error: error?.response?.data?.error, message: error?.response?.data?.message || error?.error?.message });
	}
});

export const ImportCatInfo = createAsyncThunk('import/cat', async ({ formVal }, { rejectWithValue }) => {
	try {		
		const response = await axiosConfig.post('api/category/import', formVal);
		return await response.data;
	} catch (error) {
		return rejectWithValue({ error: error?.response?.data?.error, message: error?.response?.data?.message || error?.error?.message });
	}
});