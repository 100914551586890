import React, { useCallback, useRef, useState } from "react";
import { DataTableComponent, PrimaryButton } from '../../components';
import { NavLink, useLoaderData } from "react-router-dom";
import EditIcon from "../../assets/images/icons/EditIcon.jsx";
import PdfIcon from "../../assets/images/icons/PdfIcon.jsx";
import { getAllCatalogueData, ImportCatInfo } from "../../store/catalogue/catalogueAction.js";
import store from "../../store/index.jsx";
import moment from "moment";
import { promiseToast } from "../../utils/Toast.jsx";
import { useDispatch } from "react-redux";
import { utils, writeFile } from "xlsx";

const CatalogueListing = () => {
  const catRecords = useLoaderData();
  const dispatch = useDispatch();
  const [catData, setCatData] = useState(catRecords);
  const [searchText, setSearchText] = useState('');
  const selectRef = useRef(null);
	const ref = useRef(null)
  console.log('catData',catData);
  
  const handleReset = useCallback((e) => {
    let initialValues = {
      nameOrder_val: null,
      role_val: null,
      idara_val: null,
      user_type_val: null,
      category_val: null,
    }

    selectRef?.current?.clearValue();
    // fetchEmpData(initialValues)
  }, []);

  const columns = [
    {
      key: "sr_no",
      name: 'Sr. No.',
      cell: row => row.id,
      width: "5%",
    },
    {
      key: "name",
      name: 'Category Name',
      cell: row => <><p>{row.catalogueName}</p></>,
    },
    {
      key: "category_image",
      name: 'Image',
      cell: row => {
        const pdfUrl = `${baseUrl}uploads/catalogue/${row.cataloguePdf}`;
        return (
          <a href={pdfUrl} target="_blank" rel="noopener noreferrer">
            {/* Custom PDF icon */}
            <PdfIcon />
          </a>
        );
      },
      width: "8%",
    },
    
    {
      key: "updated_at",
      name: 'Updated On',
      cell: row => moment(row.updatedAt).format('DD MMM, YYYY hh:mm A'),
      width: "16%",
    },
    {
      key: "action",
      name: 'Action',
      cell: row => {
        return (
          <>
            {
            <NavLink to={`/catalogue/edit/${row.id}`} className='action-icon' aria-label="edit" > <EditIcon height={15} width={15} /></NavLink>
            }
          </>
        )
      },
      width: "5%",
    }
  ];

  const formattedData = catData.map(item => ({
    ...item,
    updatedAt: moment(item.updatedAt).format('DD MMM, YYYY hh:mm A')
  }));

  const filteredData = formattedData.filter(item =>
    Object.values(item).some(value =>
      value !== null && value !== undefined && 
      value.toString().toLowerCase().includes(searchText.toLowerCase())
    )
  );

  const [excelFile, setExcelFile] = useState(null);

  const handleImageChange = (event) => {
    let file;
    file = event.target.files[0];
    if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
        };
        reader.readAsDataURL(file);
        setExcelFile(file);
    }
    
  };

  const submitform = async () => {
    let formVal = new FormData();
    formVal.append("file", excelFile);

    await promiseToast(
      dispatch(ImportCatInfo({ formVal })),
      "Uploading...",
      "Category Added Successfully!"
    );
    setExcelFile(null);
    ref.current.click();
    let return_data = await store.dispatch(getAllCatalogueData());
    setCatData(return_data.payload.category);
  };

  const Edata = [
    ["name", "image", ]
  ];
  
  const exportToExcel = () => {
    const worksheet = utils.aoa_to_sheet(Edata);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Sheet1");
    writeFile(workbook, "sample.xlsx");
  };

   const baseUrl = process.env.REACT_APP_BASE_URL;
    
  return (
    <>
      <div className="page-title">
        <h2>All Catalogue</h2>
      </div>
      <div className="row listing_wrapper">
        <div className="col-md-3">
            <input type="search" className="table_search" placeholder="Search Anything.."
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)} />
        </div>
        <div className="col-md-9">
          <div className="filter-section">
            
            <h6 className="w-25 mb-0 pt-1">Total Catalogue : { filteredData.length }</h6>
            
            <div className="action-btn">
                <NavLink to="/catalogue/add">
                  <PrimaryButton
                    textTransform={"capitalize"}
                    background={"#fff"}
                    height={"30px"}
                  >
                    Add +
                  </PrimaryButton>
                </NavLink>
            </div>
          </div>
        </div>
        </div>
        <div className="table_wrapper mt-3">
            <DataTableComponent
                columns={columns}
                data={filteredData}
                pagination
            />

        </div>
    </>
  );
};

export default CatalogueListing;


export async function fetchcatalogueData() {
  let return_data = await store.dispatch(getAllCatalogueData());
  const user = return_data.payload.catalogue;
  
  return user || null;
}