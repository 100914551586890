import React, { useRef, useState } from "react";
import { DataTableComponent, PrimaryButton } from '../../components';
import { NavLink, useLoaderData } from "react-router-dom";
import EditIcon from "../../assets/images/icons/EditIcon.jsx";
import { getAllCatData, ImportCatInfo } from "../../store/category/categoryAction.js";
import store from "../../store/index.jsx";
import moment from "moment";
import { promiseToast } from "../../utils/Toast.jsx";
import { useDispatch } from "react-redux";

const NotificationListing = () => {
    // const catRecords = useLoaderData();
    const dispatch = useDispatch();
    const [catData, setCatData] = useState([]);
    const [searchText, setSearchText] = useState('');
	const ref = useRef(null)

  const columns = [
    {
      key: "sr_no",
      name: 'Sr. No.',
      cell: row => '-',
      width: "10%",
    },
    {
      key: "name",
      name: 'Title',
      cell: row => '-',
      width: "20%",
    },
    {
      key: "product",
      name: 'Type',
      cell: row => '-',
      width: "20%",
    },
    {
      key: "description",
      name: 'Description',
      cell: row => '-',
    },
    {
      key: "updated_at",
      name: 'Updated On',
    //   cell: row => moment(row?.updatedAt).format('DD MMM, YYYY hh:mm A'),
      cell: row => '-',
      width: "16%",
    },
    {
      key: "action",
      name: 'Action',
      cell: row => {
        return (
          <>
            {
            // <NavLink to={`/category/edit/${row.id}`} className='action-icon' aria-label="edit" > <EditIcon height={15} width={15} /></NavLink>
            <NavLink to={`/category/edit/1`} className='action-icon' aria-label="edit" > <EditIcon height={15} width={15} /></NavLink>
            }
          </>
        )
      },
      width: "5%",
    }
  ];

  const formattedData = catData?.map(item => ({
    ...item,
    updatedAt: moment(item.updatedAt).format('DD MMM, YYYY hh:mm A')
  }));

  const filteredData = formattedData?.filter(item =>
    Object.values(item).some(value =>
      value !== null && value !== undefined && 
      value.toString().toLowerCase().includes(searchText.toLowerCase())
    )
  );

  const submitform = async () => {
    let formVal = new FormData();

    await promiseToast(
      dispatch(ImportCatInfo({ formVal })),
      "Uploading...",
      "Notification Added Successfully!"
    );
    ref.current.click();
    let return_data = await store.dispatch(getAllCatData());
    setCatData(return_data.payload.category);
  };

  return (
    <>
      <div className="page-title">
        <h2>Push Notification</h2>
      </div>
      <div className="row listing_wrapper">
        <div className="col-md-3">
            <input type="search" className="table_search" placeholder="Search Anything.."
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)} />
        </div>
        <div className="col-md-9">
          <div className="filter-section">
            
            <h6 className="w-25 mb-0 pt-1">Total Notification : { filteredData?.length }</h6>
            <div className="action-btn">
                <NavLink to="/push-notification/add">
                  <PrimaryButton
                    textTransform={"capitalize"}
                    background={"#fff"}
                    height={"30px"}
                  >
                    Add +
                  </PrimaryButton>
                </NavLink>
            </div>
          </div>
        </div>
        </div>
        <div className="table_wrapper mt-3">
            <DataTableComponent
                columns={columns}
                // data={filteredData}
                pagination
            />

        </div>
    </>
  );
};

export default NotificationListing;