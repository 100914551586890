import React, { useState } from "react";
import { DataTableComponent, PrimaryButton } from '../../components';
import { NavLink, useLoaderData } from "react-router-dom";
import EditIcon from "../../assets/images/icons/EditIcon.jsx";
import { getAllBrandData } from "../../store/brand/brandAction.js";
import store from "../../store/index.jsx";
import moment from "moment";

const BrandListing = () => {
  const brandRecords = useLoaderData();
  const [brandData, setBrandData] = useState(brandRecords);
  const [searchText, setSearchText] = useState('');

  const formattedData = brandData.map(item => ({
    ...item,
    updatedAt: moment(item.updatedAt).format('DD MMM, YYYY hh:mm A')
  }));

  const filteredData = formattedData.filter(item =>
    Object.values(item).some(value =>
      value !== null && value !== undefined &&
      value.toString().toLowerCase().includes(searchText.toLowerCase())
    )
  );

  const columns = [
    {
      key: "sr_no",
      name: 'Sr. No.',
      cell: row => row.id,
      width: "5%",
    },
    {
      key: "name",
      name: 'Brand Name',
      cell: row => <><p>{row.brand_name}</p></>,
      width: "20%",
    },
    {
      key: "brand_image",
      name: 'Image',
      cell: row => <><img className="image_view" src={`${process.env.REACT_APP_BASE_URL}uploads/brand/${row.feature_image}`} crossorigin="anonymous" /></>,
      width: "8%",
    },
    {
      key: "product",
      name: 'Products',
      cell: row => row.productCount,
      //   width: "11%",
    },
    {
      key: "updated_at",
      name: 'Updated On',
      cell: row => moment(row.updatedAt).format('DD MMM, YYYY hh:mm A'),
      width: "16%",
    },
    {
      key: "action",
      name: 'Action',
      cell: row => {
        return (
          <>
            {
              <NavLink to={`/brand/edit/${row.id}`} className='action-icon' aria-label="edit" > <EditIcon height={15} width={15} /></NavLink>
            }
          </>
        )
      },
      width: "5%",
    }
  ];


  return (
    <>
      <div className="page-title">
        <h2>All Brands</h2>
      </div>
      <div className="row listing_wrapper">
        <div className="col-md-3">
          <input type="search" className="table_search" placeholder="Search Anything.." value={searchText}
            onChange={(e) => setSearchText(e.target.value)} />
        </div>
        <div className="col-md-9">
          <div className="filter-section">

            <h6 className="w-25 mb-0 pt-1">Total Brands : {filteredData.length}</h6>
            <div className="action-btn">
              <NavLink to="/brand/add">
                <PrimaryButton
                  textTransform={"capitalize"}
                  background={"#fff"}
                  height={"30px"}
                >
                  Add +
                </PrimaryButton>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      <div className="table_wrapper mt-3">
        <DataTableComponent
          columns={columns}
          data={filteredData}
          pagination
        />

      </div>
    </>
  );
};

export default BrandListing;

export async function fetchBrandData() {
  let return_data = await store.dispatch(getAllBrandData());
  const brand = return_data.payload.brand;
  return brand;
}