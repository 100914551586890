import React, { useCallback, useRef, useState } from "react";
import { NavLink, useLoaderData, useNavigate } from "react-router-dom";
import FileUpload from "../../components/controls/FileUpload.jsx";
import { DelCatInfo, getCatInfo, ModifyCatInfo, StoreCatInfo } from "../../store/category/categoryAction.js";
import { errorToast, promiseToast } from "../../utils/Toast.jsx";
import { useDispatch } from "react-redux";
import store from "../../store/index.jsx";
import Swal from "sweetalert2";

const CategoryForm = () => {
  const navigate = useNavigate();
  const catInfo = useLoaderData();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    name:catInfo?.catData?.name || '',
    tier1:catInfo?.catData?.tier1 || 0,
    tier2:catInfo?.catData?.tier2 || 0,
    tier3:catInfo?.catData?.tier3 || 0,
    tier4:catInfo?.catData?.tier4 || 0,
    tier5:catInfo?.catData?.tier5 || 0,
    image:catInfo?.catData?.image || '',
  });
  const [imageData, setImageData] = useState(null);

  
  const formHandler = (event) => {
    setFormData((prev) => ({
        ...prev,
        [event.target.name]: event.target.value
    }));
  };

  const formSubmit = async () => {
    let formVal = new FormData();
    formVal.append('name', formData.name);
    formVal.append('tier1', formData.tier1);
    formVal.append('tier2', formData.tier2);
    formVal.append('tier3', formData.tier3);
    formVal.append('tier4', formData.tier4);
    formVal.append('tier5', formData.tier5);

    if(catInfo?.catData?.id){
      if(imageData){
        formVal.append('image', imageData);
      }

      let catId = catInfo?.catData?.id;
      await promiseToast(dispatch(ModifyCatInfo({ formVal,catId })), "Updating Category Info...","Category Data Updated Successfully!");
    }else{
      formVal.append('image', imageData);
      await promiseToast(dispatch(StoreCatInfo({ formVal  })), "Adding Category Info...","Category Data Added Successfully!");
    }
  };
  const fileVal = (val) => {
    setImageData(val)
  }

  const deleteIInfo = async() => {
    const catId = catInfo?.catData?.id;
      Swal.fire({
        text: "Are you sure, you want to delete this record?",
        showCancelButton: true,
        confirmButtonColor: '#006bcc',
        cancelButtonColor: '#bc3535',
        focusCancel: true,
        fontSize: '20px',
        width: '30em',
        padding: '4em 0 5.25em'
    },
        {
            fontSize: '20px'
        }
    ).then(async (result) => {
        if (result.isConfirmed) {
            const res = await dispatch(DelCatInfo({ catId }))
            if(res?.payload?.error){
              errorToast(res?.payload?.error);
            }else{
              navigate("/category")
            }
        }
    })
  }

  return (
    <>
      <div className="page-title">
        <h2>{catInfo?.catData?.id ? 'Update' : 'Add'} Category</h2>
      </div>
      <div className="row form_space">
        <div className="col-md-9 ps-0">
          <div className="form_bg custom_height">
            <form action="">
              <div className="row">
                <div className="col-md-9">
                  <div className="input-outer">
                    <label htmlFor="cat_name">Category Title*</label>
                    <input type="text" className="input-wrapper custom-input" id="cat_name" name="name" value={formData.name} onChange={formHandler} />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-2">
                  <div className="input-outer">
                    <label htmlFor="tier1">Regular (%)*</label>
                    <input type="text" className="input-wrapper custom-input" id="tier1" name="tier1" value={formData.tier1} onChange={formHandler} />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-2">
                  <div className="input-outer">
                    <label htmlFor="tier2">Pune City (%)*</label>
                    <input type="text" className="input-wrapper custom-input" id="tier2" name="tier2" value={formData.tier2} onChange={formHandler} />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-2">
                  <div className="input-outer">
                    <label htmlFor="tier3">Tier 3 (%)*</label>
                    <input type="text" className="input-wrapper custom-input" id="tier3" name="tier3" value={formData.tier3} onChange={formHandler} />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-2">
                  <div className="input-outer">
                    <label htmlFor="tier4">Tier 4 (%)*</label>
                    <input type="text" className="input-wrapper custom-input"  id="tier4" name="tier4" value={formData.tier4} onChange={formHandler} />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-2">
                  <div className="input-outer">
                    <label htmlFor="tier5">Tier 5 (%)*</label>
                    <input type="text" className="input-wrapper custom-input" id="tier5" name="tier5" value={formData.tier5} onChange={formHandler} />
                  </div>
                </div>
              </div>
            </form>
          </div>
          {catInfo?.catData?.id &&
          <div className="d-flex gap-2 mt-3">
            <NavLink className="delete_link" onClick={deleteIInfo}>Delete Category?</NavLink>
            <span className="note">
              Ensure that this category is removed from all products.
            </span>
          </div>
          }
        </div>
        <div className="col-md-3  pe-0">
          <div className="right_sidebar">
            <div className="form_submit">
              <button type="submit" className="button-primary"  onClick={formSubmit}>
              {catInfo?.catData?.id ? 'Update' : 'Publish Now'} 
              </button>
            </div>

            <div className="form_bg mt-3">
              <FileUpload label="Upload Feature Image" value={formData.image && `uploads/category/${formData.image}`} onChange={fileVal} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CategoryForm;

export async function fetchCatInfo({ params }){
  const { catId } = params;

  let return_data = await store.dispatch(getCatInfo({catId}));
  const catData = return_data.payload.category;

  if(catId){
    return (
      {
        catData:catData,
      }
    );
  }else{
    return (
      { }
    );
  }
  
}