import './App.css';
import {RouterProvider } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Suspense } from 'react';
import router from '../routes/route.jsx';
import Loading from '../pages/Loading';

function App() {
	const { accessToken } = useSelector((state) => state.auth);
  
	return (
		<Suspense fallback={<Loading />}>
			<RouterProvider router={router(accessToken)} fallbackElement={<Loading />} />
		</Suspense>
	)
}

export default App;