import React, { useEffect, useState } from 'react';
import { errorToast } from '../../utils/Toast';

const FileUpload = ({ limit, label, onChange, value, filePath, setFormData, ...props }) => {
    let gallVal = [];
    let ImgVal = null;

    if (value && limit > 1) {
        const gallValArr = value.split(',');
        gallValArr.forEach((item) => {
            gallVal.push({ 
                url: `${process.env.REACT_APP_BASE_URL}${filePath}/${item}`, 
                type: item.toLowerCase().endsWith('.pdf') ? 'pdf' : 'image'
            });
        });
    } else if (value) {
        ImgVal = {
            url: `${process.env.REACT_APP_BASE_URL}${value}`,
            type: value.toLowerCase().endsWith('.pdf') ? 'pdf' : 'image',
        };
    }

    const [selectedImage, setSelectedImage] = useState(ImgVal);
    const [selectedGalleryImage, setSelectedGalleryImage] = useState(gallVal);
    const [selectedImageData, setSelectedImageData] = useState(null);
    const [selectedMulImage, setSelectedMulImage] = useState([]);
    const [delImage, setDelImage] = useState([]);

    const handleImageChange = (event) => {
        if (limit > 1) {
            const checkLimit = gallVal.length === 0 ? limit : limit - selectedGalleryImage.length;

            if (event.target.files.length <= checkLimit) {
                setSelectedMulImage(event.target.files);
                Array.from(event.target.files).forEach((file) => {
                    const reader = new FileReader();
                    if (gallVal.length === 0) {
                        setSelectedGalleryImage([]);
                    }
                    reader.onloadend = () => {
                        setSelectedGalleryImage((prevImages) => [
                            ...prevImages,
                            {
                                url: reader.result,
                                type: file.type.includes('pdf') ? 'pdf' : 'image',
                            },
                        ]);
                    };
                    reader.readAsDataURL(file);
                });
            } else {
                errorToast(`Maximum ${limit} files are allowed`);
                event.target.value = '';
                return;
            }
        } else {
            const file = event.target.files[0];
            setSelectedImage(null);
            if (file) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    setSelectedImage({
                        url: reader.result,
                        type: file.type.includes('pdf') ? 'pdf' : 'image',
                    });
                };
                reader.readAsDataURL(file);
                setSelectedImageData(file);
            }
        }
    };

    useEffect(() => {
        if (onChange) {
            if (limit > 1) {
                onChange(selectedMulImage);
            } else {
                onChange(selectedImageData);
            }
        }
    }, [selectedImageData, selectedMulImage]);

    const removeGalleryImage = (filePath) => {
        const latestImage = selectedGalleryImage.filter((data) => data.url !== filePath);
        setSelectedGalleryImage(latestImage);
        setFormData((prevData) => ({
            ...prevData,
            del_gallery_img: [...prevData.del_gallery_img, { path: filePath }],
        }));
    };

    return (
        <>
            {label && <label htmlFor="" className="text-center">{label}</label>}
            <input
                multiple={limit > 1}
                className="mt-2 file_field"
                type="file"
                onChange={handleImageChange}
                name=""
                id=""
                accept=".png,.jpg,.jpeg,.webp,.pdf"
            />

            {selectedImage && (
                <div className="feature_image">
                    {selectedImage.type === 'image' ? (
                        <img
                            src={selectedImage.url}
                            alt=""
                            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                            crossOrigin="anonymous"
                        />
                    ) : (
                        <iframe
                            src={selectedImage.url}
                            title="PDF Preview"
                            style={{ width: '100%', height: '300px', border: 'none' }}
                        />
                    )}
                </div>
            )}

            {limit > 1 && (
                <div className="gallery_image">
                    {selectedGalleryImage.map((data, key) => (
                        <div key={key} className="gall_item position-relative">
                            {data.url.includes(process.env.REACT_APP_BASE_URL) && (
                                <span onClick={() => removeGalleryImage(data.url)}>x</span>
                            )}
                            {data.type === 'image' ? (
                                <img src={data.url} alt="" crossOrigin="anonymous" />
                            ) : (
                                <iframe
                                    src={data.url}
                                    title={`PDF Preview ${key}`}
                                    style={{ width: '100%', height: '150px', border: 'none' }}
                                />
                            )}
                        </div>
                    ))}
                </div>
            )}
        </>
    );
};

export default FileUpload;
