import { createSlice } from '@reduxjs/toolkit';
import { getAllCityData } from './cityAction';

const initialState = {
	loading: false,
	city_details: null,
	error: null,
};

const citySlice = createSlice({
	name: 'city',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getAllCityData.pending, (state) => {
				state.loading = true;
			})
			.addCase(getAllCityData.fulfilled, (state, action) => {
				state.loading = false;
				state.city_details = action.payload.cities;
				state.error = null;
			})
			.addCase(getAllCityData.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload.error;
			});
	},
});

export default citySlice.reducer;