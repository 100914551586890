
import React from 'react'
import './header.css'
import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux';

const Header = () => {
	const { user } = useSelector((state) => state.auth);
    
    
    return (
        <header className='header'>
            { user?.role === 'super_admin' && 
            <>
            <NavLink to="/push-notification" className='link-btn' >
                Push Notification
            </NavLink>
            <NavLink to="/access-panel" className='link-btn' >
                Access Panel
            </NavLink>
            </>
            }

             <div className="user-info">
                <p className="header-user-name ellipsis text-nowrap">
                    { user?.name }
                </p>
                <p className="user-role">
                { user?.role === 'super_admin' ? 'Super Admin' : 'Admin' }
                </p>
            </div>
        </header>)
}

export default Header