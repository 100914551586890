import { createSlice } from '@reduxjs/toolkit';
import { DelCatInfo, getAllCatData, getCatInfo, ImportCatInfo, ModifyCatInfo, StoreCatInfo } from './categoryAction';

const initialState = {
	loading: false,
	category_data: null,
	filterText: {},
	error: null,
};

const categorySlice = createSlice({
	name: 'category',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getAllCatData.pending, (state) => {
				state.loading = true;
			})
			.addCase(getAllCatData.fulfilled, (state, action) => {
				state.loading = false;
				state.category_data = action.payload.category;
				state.error = null;
			})
			.addCase(getAllCatData.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload.error;
			})
			.addCase(StoreCatInfo.pending, (state) => {
				state.loading = true;
			})
			.addCase(StoreCatInfo.fulfilled, (state, action) => {
				state.loading = false;
				// state.category_data = action.payload.category;
				state.error = null;
			})
			.addCase(StoreCatInfo.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload.error;
			})
			.addCase(getCatInfo.pending, (state) => {
				state.loading = true;
			})
			.addCase(getCatInfo.fulfilled, (state, action) => {
				state.loading = false;
				// state.category_data = action.payload.category;
				state.error = null;
			})
			.addCase(getCatInfo.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload.error;
			})
			.addCase(ModifyCatInfo.pending, (state) => {
				state.loading = true;
			})
			.addCase(ModifyCatInfo.fulfilled, (state, action) => {
				state.loading = false;
				// state.category_data = action.payload.category;
				state.error = null;
			})
			.addCase(ModifyCatInfo.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload.error;
			})
			.addCase(DelCatInfo.pending, (state) => {
				state.loading = true;
			})
			.addCase(DelCatInfo.fulfilled, (state, action) => {
				state.loading = false;
				// state.category_data = action.payload.category;
				state.error = null;
			})
			.addCase(DelCatInfo.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload.error;
			})
			.addCase(ImportCatInfo.pending, (state) => {
				state.loading = true;
			})
			.addCase(ImportCatInfo.fulfilled, (state, action) => {
				state.loading = false;
				state.error = null;
			})
			.addCase(ImportCatInfo.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload.error;
			});
	},
});

export default categorySlice.reducer;