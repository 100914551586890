import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosConfig from '../../utils/axiosConfig';

export const getDashboardData = createAsyncThunk('get_desktop', async ({ start_date, end_date }, { rejectWithValue }) => {
	try {
		const response = await axiosConfig.post('api/lead/get-dashboard/',{ start_date, end_date });
		return await response.data;
	} catch (error) {
		return rejectWithValue({ error: error?.response?.data?.error, message: error?.response?.data?.message || error?.error?.message });
	}
});