import React, { useState } from "react";
import { DataTableComponent, PrimaryButton } from '../../components';
import { NavLink, useLoaderData } from "react-router-dom";
import EditIcon from "../../assets/images/icons/EditIcon.jsx";
import store from "../../store/index.jsx";
import moment from "moment";
import { getAllAccessData, getAllAccessRecords } from "../../store/access/accessAction.js";

const AccessListing = () => {
  const accessRecords = useLoaderData();
  
  const [brandData, setBrandData] = useState(accessRecords);
  const [searchText, setSearchText] = useState('');
  
  const formattedData = brandData.map(item => ({
    ...item,
    updatedAt: moment(item.updatedAt).format('DD MMM, YYYY hh:mm A')
  }));

  const filteredData = formattedData.filter(item =>
    Object.values(item).some(value =>
      value !== null && value !== undefined && 
      value.toString().toLowerCase().includes(searchText.toLowerCase())
    )
  );
  
  const columns = [
    {
      key: "name",
      name: 'User Name',
      cell:row => <><p>{row.username}</p></>,
      width: "15%",
    },
    {
      key: "product",
      name: 'Products',
      cell: row => <><p>{row?.modules.map(module => module.name).join(', ')}</p></>,
    },
    {
      key: "action",
      name: 'Action',
      cell: row => {
        return (
          <>
            {
            <NavLink to={`/access-panel/edit/${row.id}`} className='action-icon' aria-label="edit" > <EditIcon height={15} width={15} /></NavLink>
            }
          </>
        )
      },
      width: "5%",
    }
  ];

    
  return (
    <>
      <div className="page-title">
        <h2>Access Panel</h2>
      </div>
      <div className="row listing_wrapper">
        <div className="col-md-3">
            <input type="search" className="table_search" placeholder="Search Anything.." value={searchText}
            onChange={(e) => setSearchText(e.target.value)} />
        </div>
        <div className="col-md-9">
          <div className="filter-section">
            
            <h6 className="w-25 mb-0 pt-1">Total Brands : { filteredData.length }</h6>
            <div className="action-btn">
                <NavLink to="/access-panel/add">
                  <PrimaryButton
                    textTransform={"capitalize"}
                    background={"#fff"}
                    height={"30px"}
                  >
                    Add +
                  </PrimaryButton>
                </NavLink>
            </div>
          </div>
        </div>
        </div>
        <div className="table_wrapper mt-3">
            <DataTableComponent
                columns={columns}
                data={filteredData}
                pagination
            />

        </div>
    </>
  );
};

export default AccessListing;

export async function fetchAccessData() {
  let return_data = await store.dispatch(getAllAccessRecords());
  const brand = return_data.payload.access;
  return brand;
}