import React, { useEffect } from "react";
import Header from "./header/Header";
import Sidebar from "./Sidebar";
import "bootstrap/dist/js/bootstrap.bundle.js";
import "bootstrap/dist/css/bootstrap.css";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { verifyUserDetails } from "../store/auth/authActions";

const AdminLayout = () => {
  
	const { accessToken } = useSelector((state) => state.auth);
	const dispatch = useDispatch();

	useEffect(() => {
		if (accessToken) {
			dispatch(verifyUserDetails());
		}
	}, [accessToken, dispatch]);

  return (
    <div className="main-container">
        <ToastContainer />
        <div className="sidebar_wrapper">
          <Sidebar />
        </div>
        <div className="main_wrapper">
          <div className="main-header">
            <Header />
          </div>
          <div className="main-content">
            <Outlet />
          </div>
        </div>
    </div>
  );
};

export default AdminLayout;
