import React, { useCallback, useState } from "react";
import "./login.css";
import { InputField, PrimaryButton } from "../../components";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { loginUser } from "../../store/auth/authActions";
import { promiseToast } from "../../utils/Toast";
import { ToastContainer } from "react-toastify";

const Login = () => {
  const dispatch = useDispatch()
  const [userExist, setUserExist] = useState(2);

  const [userData, setUserData] = useState({
    username: "",
    password: "",
  });
  
  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setUserData({ ...userData, password: newPassword });
  };

  const handleUsername = async (e) => {
    const username = e.target.value
    setUserData({ ...userData, username });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await promiseToast(dispatch(loginUser({ username: userData.username, password: userData.password })), "logging in...","Logged in")
  };

  return (
    <div className="login-wrapper">
      <ToastContainer />
      <div className="login-outer">
        <div className="login-inner">
          <div className="login-logo">
            <img src="images/main-logo.png" />
          </div>
          <form className="input-fields" onSubmit={handleSubmit}>
            <InputField
              id="email"
              valid={userExist}
              type={'text'}
              label={"Login via Email ID"}
              value={userData.username}
              onChange={(e) => handleUsername(e)}
              placeholder="Type your email"
              required
            />
            <InputField
                  type="password"
                  id="password"
                  label={"Login Password"}
                  value={userData.password}
                  onChange={handlePasswordChange}
                  icon={false}
                  isPass={true}
                  placeholder="Type your password"
                  required
                />

                <PrimaryButton type="submit" >
                  Sign in
                </PrimaryButton>
          </form>
          <div className="forget-pass">
            <NavLink to="/forgot">Forgot Password</NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;