import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosConfig from '../../utils/axiosConfig';

export const getAllCityData = createAsyncThunk('get_city', async ({ state_id }, { rejectWithValue }) => {
	try {
		const response = await axiosConfig.get('api/getData/cities/'+state_id);
		return await response.data;
	} catch (error) {
		return rejectWithValue({ error: error?.response?.data?.error, message: error?.response?.data?.message || error?.error?.message });
	}
});