import React, { useCallback, useEffect, useRef, useState } from "react";
import { Calendar, DataTableComponent, PrimaryButton, Reset } from '../../components';
import CsvIcon from "../../assets/images/icons/CsvIcon.jsx";
import { NavLink, useLoaderData } from "react-router-dom";
import EditIcon from "../../assets/images/icons/EditIcon.jsx";
import Valid from "../../assets/images/icons/Valid.jsx";
import { getAllProductData, ImportProdInfo } from "../../store/product/productAction.js";
import store from "../../store/index.jsx";
import moment from "moment";
import InValid from "../../assets/images/icons/InValid.jsx";
import { CSVLink } from "react-csv";
import { useDispatch } from "react-redux";
import { promiseToast } from "../../utils/Toast.jsx";
import { utils, writeFile } from "xlsx";

const ProductListing = () => {
  const selectRef = useRef(null);
  const productRecords = useLoaderData();

  const dispatch = useDispatch();
	const ref = useRef(null)

  const [productData, setProductData] = useState(productRecords);
  const [searchText, setSearchText] = useState('');
  const [uniqueBrands, setUniqueBrands] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);

  const [filterData, setFilterData] = useState({
    selling_val: "",
    feature_val: "",
    status_val: "",
    start_date: null,
    end_date: null,
    brand_val: "",
    category_val: ""
  });

   // Extract unique brands when product data is loaded
   useEffect(() => {
    const brands = Array.from(new Set(productRecords.map((product) => product.brand).filter(Boolean)));
    setUniqueBrands(brands);
  }, [productRecords]);

  const uniqueCategories = Array.from(new Set(productData.map(item => item.category)));
  const handleReset = useCallback(
    (e) => {
      let initialValues = {
        selling_val: "",      // Reset type
        feature_val: "",   // Reset special
        status_val: "",    // Reset status
        start_date: null,
        end_date: null,
        brand_val: "",
        category_val: "",
      };
      setFilterData(initialValues);
      // setSelectedProducts([]);
      selectRef?.current?.clearValue();
    },
    [setFilterData]
  );

  const handleDateChange = (dates) => {
    setFilterData(prev => ({
      ...prev,
      start_date: dates[0]?.format('YYYY-MM-DD'),
      end_date: dates[1]?.format('YYYY-MM-DD'),
    }));
  };

  const filterHandler = (event) => {
    setFilterData((prev) => ({
      ...prev,
      [event.target.name]: event.target.value
    }));
  };

  const formattedData = productData.map(item => ({
    ...item,
    updatedAt: moment(item.updatedAt).format('DD MMM, YYYY hh:mm A')
  }));

  const filteredData = formattedData.filter(item => {
    // const matchesSearchText = Object.values(item).some(value =>
    //   value !== null && value !== undefined &&
    //   value.toString().toLowerCase().includes(searchText.toLowerCase())
    // );

    const matchesSearchText = 
    (item.title && item.title.toLowerCase().includes(searchText.toLowerCase())) ||
    (item.uid && item.uid.toLowerCase().includes(searchText.toLowerCase())) ||
    (item.brand && item.brand.toLowerCase().includes(searchText.toLowerCase())) ||
    (item.category && item.category.toLowerCase().includes(searchText.toLowerCase()));

    
    const matchesType = filterData.feature_val === "" || (item.isFeatured === true ? "1" : "0") === filterData.feature_val;
    const matchesStatus = filterData.status_val === "" || (item.status === 0 ? "0" : "1") === filterData.status_val;
    
    const matchesBrand = filterData.brand_val === "" || item.brand === filterData.brand_val;
    const matchesCategory = filterData.category_val === "" || item.category === filterData.category_val; // Add this line
  
    const matchesDate = (!filterData.start_date || moment(item.updatedAt).isSameOrAfter(filterData.start_date)) &&
      (!filterData.end_date || moment(item.updatedAt).isSameOrBefore(filterData.end_date));

    return matchesSearchText && matchesType && matchesStatus  && matchesBrand  && matchesCategory && matchesDate;
  });

  
  const csvData = filteredData.map((item) =>{
    switch (parseInt(item.status)) {
      case 0:
        item.status = 'Draft';
        break;
        case 1:
        item.status = 'Published';
        break;
    }

    switch (parseInt(item.mostSelling)) {
      case 0:
        item.mostSelling = 'No';
        break;
        case 1:
        item.mostSelling = 'Yes';
        break;
    }
    item.title2 = item.subTitle;
    const { deletedAt, createdAt, updatedAt, brandId, categoryId, subTitle,  ...itemWithoutStateId } = item;
    
    return itemWithoutStateId;
  });

  const handleProductSelection = (productId) => {
    setSelectedProducts(prevState => {
      if (prevState.includes(productId)) {
        return prevState.filter(id => id !== productId); // Unselect
      } else {
        return [...prevState, productId]; // Select
      }
    });
  };
  
  // Keep only selected products present in filtered data
  // useEffect(() => {
  //   setSelectedProducts(prevState =>
  //     prevState.filter(productId => filteredData.some(product => product.id === productId))
  //   );
  // }, [filteredData]);
  
  

  const columns = [
    {
    key: "select",
    name: "Select",
    cell: row => (
      <input
        type="checkbox"
        checked={selectedProducts.includes(row.id)}
        onChange={() => handleProductSelection(row.id)}
      />
    ),
    width: "5%",
    },
    {
      key: "pid",
      name: 'PID',
      cell: row => row.uid,
      width: "10%",
    },
    {
      key: "name",
      name: 'Name',
      cell: row => <><p>{row.title}</p></>,
      // width: "17%",
    },
    {
      key: "price_pc",
      name: 'Price Per',
      cell: row => <span>{row.price} | {row.unit}</span>,
      width: "9%",
    },
    {
      key: "category",
      name: 'Category',
      cell: row => <p className="valid_icon">{row.category}</p>,
      width: "8%",
    },
    {
      key: "order",
      name: 'Orders',
      cell: row => row.ordersCount,
      width: "7%",
    },
    {
      key: "selling",
      name: 'Selling',
      cell: row => <>
        {row.isFeatured === true ? <p className="valid_icon"><Valid /></p> : <p className="valid_icon"><Valid /></p>}
      </>,
      width: "6%",
    },
    {
      key: "featured",
      name: 'Featured',
      cell: row => <>
        {row.isFeatured === true ? <p className="valid_icon"><Valid /></p> : <p className="valid_icon"><InValid /></p>}
      </>,
      width: "7%",
    },
    {
      key: "updated_at",
      name: 'Updated On',
      cell: row => row.updatedAt,
      width: "14%",
    },
    {
      key: "status",
      name: 'Status',
      cell: row => <>
        {row.status === 'Published' ? <p className="status_action publish_status">Published</p> : <p className="status_action pending_status">Draft </p>}
      </>,
      width: "10%",
    },
    {
      key: "action",
      name: 'Action',
      cell: row => {
        return (
          <>
            {
              <NavLink to={`/products/${row.id}`} className='action-icon' aria-label="edit" > <EditIcon height={15} width={15} /></NavLink>
            }
          </>
        )
      },
      width: "5%",
    }
  ];

  
  const [excelFile, setExcelFile] = useState(null);

  const handleImageChange = (event) => {
    let file;
    file = event.target.files[0];
    if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
        };
        reader.readAsDataURL(file);
        setExcelFile(file);
    }
    
  };

  const submitform = async () => {
    let formVal = new FormData();
    formVal.append("file", excelFile);

    await promiseToast(
      dispatch(ImportProdInfo({ formVal })),
      "Uploading...",
      "Product Imported Successfully!"
    );
    setExcelFile(null);
    ref.current.click();
    let return_data = await store.dispatch(getAllProductData());
    setProductData(return_data.payload.products);
  };

  const Edata = [
    ["Product_Title", "Product_Id", "Sub_Product_Title", "Unit_Type", "Price", "Description", "Category", "Brand", "Featured","Thumbnail_image", "product_image"]
  ];
  
  const exportToExcel = () => {
    const worksheet = utils.aoa_to_sheet(Edata);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Sheet1");
    writeFile(workbook, "Product_sample.xlsx");
  };

  const productExport = async () => {
    const headers = [
      'id', 'Product_Title', 'Product_Id', 'Sub_Product_Title', 'Unit_Type',
      'Price', 'Description', 'Category', 'Brand', 'Featured', 'Thumbnail_image', 'product_image'
    ];
  
    const dataToExport = selectedProducts.length > 0
      ? filteredData.filter(product => selectedProducts.includes(product.id)) // Export selected products only
      : filteredData; // Export filtered data if no product is selected
  
    const csvRows = [
      headers,
      ...dataToExport.map(({ id, title, uid, title2, unit, price, description, category, brand, isFeatured, thumbnailImage, images }) => [
        id, title, uid, title2, unit, price, description, category, brand, isFeatured ? 'yes' : 'no', thumbnailImage, images
      ])
    ];
  
    const worksheet = utils.aoa_to_sheet(csvRows);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Sheet1");
    writeFile(workbook, "Product_Data.xlsx");
  };
  

  
  

  return (
    <>
      <div className="page-title">
        <h2>All Products</h2>
      </div>
      <div className="row listing_wrapper">
        <div className="col-md-3">
          <input type="search" className="table_search" placeholder="Search Anything.." onChange={(e) => setSearchText(e.target.value)} />
        </div>
        <div className="col-md-9">
          <div className="filter-section">
            {/* <div>
              <select name="" id="" value="" className="field_dropdown">
                  <option value="">Select</option>
              </select>
            </div>
            <div>
            <select name="" id="" value="" className="field_dropdown">
                <option value="">Select</option>
            </select>
            </div> */}
            <div>
            <select
                name="category_val"
                id="category_val"
                className="field_dropdown"
                value={filterData.category_val}
                onChange={filterHandler}
              >
                <option value="">Select Category</option>
                {uniqueCategories.map((category, index) => (
                  <option key={index} value={category}>{category}</option>
                ))}
              </select>
            </div>
            <div>
            <select
            name="brand_val"
            id="brand_val"
            className="field_dropdown"
            value={filterData.brand_val}
            onChange={filterHandler}
          >
            <option value="">Select Brand</option>
            {uniqueBrands.map((brand, index) => (
              <option key={index} value={brand}>
                {brand}
              </option>
            ))}
          </select>
            </div>
            <div>
              <select name="feature_val" id="feature_val" className="field_dropdown" value={filterData.feature_val} onChange={filterHandler}>
                <option value="">Select Feature</option>
                <option value="1">Yes</option>
                <option value="0">No</option>
              </select>
            </div>
            <div>
              <select name="status_val" id="status_val" className="field_dropdown" value={filterData.status_val} onChange={filterHandler}>
                <option value="">Select Status</option>
                <option value="0">Draft</option>
                <option value="1">Published</option>
              </select>
            </div>
            <Calendar
              inputRef={selectRef}
              format="YYYY-MM-DD"
              range
              rangeHover
              onChange={handleDateChange}
            />
            <Reset height='30px' padding='0 6px' onClick={handleReset} />

            <div className="download-sec">
              <button type="button" className="icon-wrapper" onClick={productExport}>
                {/* <CSVLink
                  data={csvData}
                  filename="product_data.csv"
                  target="_blank"
                > */}
                  <CsvIcon height={20} width={20} />
                {/* </CSVLink> */}
              </button>
            </div>
            <div className="action-btn">
              <PrimaryButton
                  textTransform={"capitalize"}
                  background={"#fff"}
                  height={"30px"}
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  Import
                </PrimaryButton>
                <div
                  className="modal fade"
                  id="exampleModal"
                  tabindex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-md modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-header p-0 mb-4">
                        <h4 className="modal-title" id="exampleModalLabel">
                          Import Product
                        </h4>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          ref={ref}
                        ></button>
                      </div>
                      <div className="modal-body p-0">
                        <form action="" >
                          <div className="w-50 m-auto">
                            <input type="file" className="file_field" name="" id="" onChange={handleImageChange} />
                          </div>

                          <div className="col-md-12 text-center mt-3">
                            <button
                              type="button"
                              className="edit_button  me-3"
                              onClick={submitform}
                            >
                              Submit
                            </button>
                            <button className="edit_button text-decoration-none" onClick={exportToExcel}>Download XLSX</button>
                             
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>

              <NavLink to="/products/add" className="ms-2">
                <PrimaryButton
                  textTransform={"capitalize"}
                  background={"#fff"}
                  height={"30px"}
                >
                  Add +
                </PrimaryButton>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      <div className="table_wrapper mt-3">
        <DataTableComponent
          columns={columns}
          data={filteredData}
          pagination
        />

      </div>
    </>
  );
};

export default ProductListing;

export async function fetchProductData() {
  let return_data = await store.dispatch(getAllProductData());
  const product = return_data.payload.products;
  return product || [];
}