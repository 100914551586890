import React, { useState } from "react";
import { NavLink, useLoaderData, useNavigate } from "react-router-dom";
import { EditIcon } from "../../assets/images/icons";
import { DelLeadInfo, getLeadInfo, ModifyLeadInfo } from "../../store/lead/leadAction";
import store from "../../store";
import moment from "moment";
import { promiseToast } from "../../utils/Toast";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import LeadPdf from "../../layout/pdf/LeadPdf";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";

const LeadForm = () => {
  const userInfo = useLoaderData();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const leadDetails = userInfo.leadInfo;
  const productDetails = userInfo.productInfo;

  const [leadData, setLeadData] = useState(leadDetails);
  const [productData, setProductData] = useState(productDetails);
  
  const [productEditData, setProductEditData] = useState(false);
  const [formData, setFormData] = useState({
    shippingAddress:leadData.shippingAddress,
    status:leadData.status,
    notes:leadData.notes
  });
  
  const productformHandler = (key , val) => {
    const updatedItems = productData.map((item, newKey) =>
      newKey === key ? { ...item, qty: val } : item
    );
    setProductData(updatedItems);
  }

  const form_Handler = (event) => {
    setFormData((prev) => ({
      ...prev,
      [event.target.name]: event.target.value
    }));
  }

  const submitform = async(type) => {
    let formPayload;
    if(type === 'shiping'){
      formPayload = { shippingAddress : formData.shippingAddress }
    }else{
      formPayload = { 
        status: formData.status,
        notes: formData.notes,
      }
    }
    await promiseToast(dispatch(ModifyLeadInfo({ formVal : formPayload, leadId:leadData.id, type:'form' })), "Updating Lead Info...","Lead Data Updated Successfully!");
    const returnData = await dispatch(getLeadInfo({ lead_id:leadData.id }));
    setLeadData(returnData.payload.lead);   
  }

  const updateProduct = async() => {
    setProductEditData(false)

    let arr = [];
     productData.map((item) =>
      arr.push({ id: item.id, qty: parseInt(item.qty) })
    );    
    
    await promiseToast(dispatch(ModifyLeadInfo({ formVal : arr, leadId:leadData.id, type:'product' })), "Updating Product Info...","Product Data Updated Successfully!");
    const returnData = await dispatch(getLeadInfo({ lead_id:leadData.id }));
    setLeadData(returnData.payload.lead);    
  }

  const deleteIInfo = async() => {
      Swal.fire({
        text: "Are you sure, you want to delete this record?",
        showCancelButton: true,
        confirmButtonColor: '#006bcc',
        cancelButtonColor: '#bc3535',
        focusCancel: true,
        fontSize: '20px',
        width: '30em',
        padding: '4em 0 5.25em'
    },
        {
            fontSize: '20px'
        }
    ).then(async (result) => {
        if (result.isConfirmed) {
            await dispatch(DelLeadInfo({ leadId : leadData.id }))
            navigate("/leads")
        }
    })
  }
  
  return (
    <>
      <div className="page-title">
        <h2>Order Details</h2>
      </div>
      <div className="row form_space">
        <div className="col-md-9 ps-0">
          <div className="form_bg custom_height">
            <form action="">
              <div className="row">
                <div className="col-md-6">
                  <h4 className="order_title">Order { leadData.oid } Details:</h4>
                  <p className="order_date">{moment(leadData.createdAt).format('DD MMM, YYYY hh:mm A')}</p>
                </div>
                <div className="col-md-6">
                  <div className="addres_details">
                    <p>{ leadData.user.companyName }</p>
                    <p>{ leadData.shippingAddress }</p>
                    <NavLink
                          to=""
                          data-bs-toggle="modal"
                          data-bs-target="#addessModal"
                          className="action-icon"
                          aria-label="edit"
                        >
                          {" "}
                          <EditIcon height={15} width={15} />
                        </NavLink>
                  </div>
                </div>
                <div className="lead_table">
                  <div className="row  align-items-center">
                    <div className="col-md-6">
                    <div className="d-flex gap-4">
                        <div>
                          <p className="user_name mb-0">{ leadData.user.username }</p>
                          <p className="company_name mb-0"><i>{ leadData.user.companyName }</i></p>
                        </div>
                        <div>
                          <p className="user_phone mb-0">{ leadData.user.number }</p>
                          <p className="user_email mb-0">{ leadData.user.email }</p>
                        </div>
                    </div>
                    </div>
                    <div className="col-md-6">
                      <div className="d-flex gap-2 justify-content-end">
                      <PDFDownloadLink className='pdf-down-link' document={<LeadPdf pdfData={productData} orderData={leadData} />} fileName={`Order_${leadData.oid}.pdf`} >
                        {({ blob, url, loading, error }) =>
                          loading ? <button type='button' className="pdf_button">Loading</button> : <button type='button' className="pdf_button">Genrate PDF</button>
                        }
                      </PDFDownloadLink>
                      
                        
                        { productEditData === true ?                        
                        <button type='button' className="edit_button" onClick={() => updateProduct()} >Save</button>
                      :                      
                        <button type='button' className="edit_button" onClick={() => setProductEditData(true)} >Edit</button>
                      }
                        
                      </div>
                    </div>
                    <div className="col-md-12 mt-3">
                    {/* <PDFViewer width="100%" height="400" >
                      <LeadPdf pdfData={productData} orderData={leadData} />
                    </PDFViewer> */}

                    

                      <table width={"100%"}>
                          <tr>
                            <th>Id</th>
                            <th>Item</th>
                            <th >Qty</th>
                            <th>UOM</th>
                            <th>Per Pc</th>
                            <th>Total</th>
                          </tr>
                          { productData?.map((item, key) => {
                            const totalRate = parseInt(item.qty) * parseFloat(item.priceAfterDiscount);
                            return (
                              <>
                                <tr key={key}>
                                  <td>{ item.uid }</td>
                                  <td>{ item.title }</td>
                                  <td >{ productEditData === true ? <input min="1" type="number" value={ item.qty } className="prod_table_input input-wrapper custom-input" onChange={(e) => productformHandler(key, e.target.value )} /> : item.qty }</td>
                                  <td>{ item.unit }</td>
                                  <td>&#x20b9; { item.priceAfterDiscount.toFixed(2) }</td>
                                  <td>&#x20b9; { totalRate.toFixed(2) }</td>
                                </tr>
                              </>
                            )
                          }) }
                          
                      </table>
                      <div className="total_amount">
                        <p className="total_price mb-0">Total : &#x20b9; { leadData.total.toFixed(2) }</p>
                        <label className="mb-0"><i>*Inclusive GST</i></label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mt-4">
                  <label htmlFor="">Any Notes</label>
                    <textarea
                      name="notes"
                      id="notes"
                      className="textarea_field"
                      rows="2"
                      cols="3"
                      value={ formData.notes }
                      onChange={form_Handler}
                    ></textarea>
                </div>
              </div>
            </form>
          </div>
          <div className="d-flex gap-2 mt-3">
            <NavLink className="delete_link" onClick={deleteIInfo}>Delete Order?</NavLink>
            <span className="note">
              If you delete this Order, it will be removed from all locations, and the user will no longer have any records of it.
            </span>
          </div>
        </div>
        <div className="col-md-3  pe-0">
          <div className="right_sidebar">
            <div className="form_submit">
              <div className="input-outer">
                <select name="status" value={formData.status} onChange={form_Handler}>
                  <option value="0">Placed</option>
                  <option value="1">Confirmed</option>
                  <option value="2">Dispatched</option>
                  <option value="3">Cancelled</option>
                </select>
              </div>
              <button type="submit" className="button-primary" onClick={() => submitform('submit') }>
                Update
              </button>
            </div>

            <div className="revisions mt-3">
              <label className="mb-0"><i>Revisions:</i></label>
              <div className="custom_re_height">
                { leadData.orderActivities.map((item) => {
                  return (
                    <>
                      <p>{ item.comment } on {moment(item.createdAt).format('DD MMM, YYYY hh:mm A')}</p>
                    </>
                  )
                }) }
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="addessModal"
        tabindex="-1"
        aria-labelledby="addessModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header p-0">
              <h4 className="modal-title" id="addessModalLabel">
              Shipping Address 
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body p-0">
              <div className="row align-items-center">
                <div className="col-md-12 form_bg bg-white pb-0">
                  <div className="input-outer">
                    <label htmlFor="type">Shipping Address</label>
                    <textarea
                      name="shippingAddress"
                      id="shippingAddress"
                      className="textarea_field"
                      rows="3"
                      cols="3"
                      value={ formData.shippingAddress }
                      onChange={form_Handler}
                    >{ formData.shippingAddress }</textarea>
                  </div>
                  
                </div>
                <div className="col-md-12 text-center">
                  <button
                    type="button"
                    className="edit_button"
                    onClick={() => submitform('shiping') }
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </>
  );
};

export default LeadForm;

export async function fetchLeadInfo({ params }){
  const { lead_id } = params;
  let leadInfo, productInfo;

  let return_lead_data = await store.dispatch(getLeadInfo({lead_id}));
  leadInfo = return_lead_data.payload.lead;
  productInfo = return_lead_data.payload.products;

  return (
    {
      leadInfo:leadInfo,
      productInfo:productInfo,
    }
  );
}