import React, { useEffect, useRef, useState } from 'react';
import $ from 'jquery';
import 'select2';

const SingleDropdown = ({ options, placeholder, onChange, value }) => {
    const selectRef = useRef();
    const [filteredOptions, setFilteredOptions] = useState(options);

    useEffect(() => {
        $(selectRef.current).select2({
            placeholder: placeholder,
            minimumInputLength: 0,
            allowClear: true
        });

        const handleChange = () => {
            const selectedValue = $(selectRef.current).val();            
            onChange(selectedValue);
        };

        const handleInput = function() {
            const searchTerm = $(this).val().toLowerCase();
            const results = options.filter(option =>
                option.text.toLowerCase().includes(searchTerm)
            );

            $(this).empty();

            results.forEach(option => {
                const newOption = new Option(option.text, option.id, false, false);
                $(this).append(newOption);
            });

            $(this).trigger('change');
        };

        $(selectRef.current).on('change', handleChange);
        $(selectRef.current).on('select2:open', () => {
            $(document).on('input', '.select2-search__field', handleInput);
        });

        return () => {
            $(selectRef.current).select2('destroy');
            $(document).off('input', '.select2-search__field', handleInput);
            $(selectRef.current).off('change', handleChange);
        };
    }, [options]);

    useEffect(() => {
        if (selectRef.current && value) {
            $(selectRef.current).val(value).trigger('change');
        }
    }, [value]);

    return (
        <select ref={selectRef} style={{ width: '100%' }}>
            <option value="">{ placeholder }</option>
            {filteredOptions.map(option => (
                <option key={option.id} value={option.id}>
                    {option.text}
                </option>
            ))}
        </select>
    );
};

export default SingleDropdown;
