import React, { useCallback, useRef, useState } from "react";
import { NavLink, useLoaderData, useNavigate } from "react-router-dom";
import FileUpload from "../../components/controls/FileUpload.jsx";
import FileUploadPDF from "../../components/controls/FileUploadsPDF.jsx";
import { DelCateloagueInfo, getCatalogueInfo, ModifyCatalogueInfo, StoreCatInfo } from "../../store/catalogue/catalogueAction.js";
import { errorToast, promiseToast } from "../../utils/Toast.jsx";
import { useDispatch } from "react-redux";
import store from "../../store/index.jsx";
import Swal from "sweetalert2";

const CatalogueForm = () => {
  const navigate = useNavigate();
  const catInfo = useLoaderData();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    name:catInfo?.catData?.catalogueName || '',
    image:catInfo?.catData?.cataloguePdf || '',
  });
  
  const [imageData, setImageData] = useState(null);

  
  const formHandler = (event) => {
    setFormData((prev) => ({
        ...prev,
        [event.target.name]: event.target.value
    }));
  };

  const formSubmit = async () => {
    let formVal = new FormData();
    formVal.append('name', formData.name);
    

    if(catInfo?.catData?.id){
      if(imageData){
        formVal.append('cataloguePdf', imageData);
      }

      let catId = catInfo?.catData?.id;
      const res =  await promiseToast(dispatch(ModifyCatalogueInfo({ formVal,catId })), "Updating Category Info...","Category Data Updated Successfully!");
      if (res?.payload?.error) {
        errorToast(res?.payload?.error);
      } else {
        // If successful, navigate to catalogue page
        navigate("/catalogue");
      }
    }else{
      formVal.append('cataloguePdf', imageData);
      const res =  await promiseToast(dispatch(StoreCatInfo({ formVal  })), "Adding Category Info...","Category Data Added Successfully!");
    
      if (res?.error) {
        errorToast(res?.payload?.error);
      } else {
        // If successful, navigate to catalogue page
        navigate("/catalogue");
      }}
  };
  const fileVal = (val) => {
    setImageData(val)
  }

  const deleteIInfo = async() => {
    const catId = catInfo?.catData?.id;
      Swal.fire({
        text: "Are you sure, you want to delete this record?",
        showCancelButton: true,
        confirmButtonColor: '#006bcc',
        cancelButtonColor: '#bc3535',
        focusCancel: true,
        fontSize: '20px',
        width: '30em',
        padding: '4em 0 5.25em'
    },
        {
            fontSize: '20px'
        }
    ).then(async (result) => {
        if (result.isConfirmed) {
            const res = await dispatch(DelCateloagueInfo({ catId }))
            if(res?.payload?.error){
              errorToast(res?.payload?.error);
            }else{
              navigate("/catalogue")
            }
        }
    })
  }

  return (
    <>
      <div className="page-title">
        <h2>{catInfo?.catData?.id ? 'Update' : 'Add'} Catalogue</h2>
      </div>
      <div className="row form_space">
        <div className="col-md-9 ps-0">
          <div className="form_bg custom_height">
            <form action="">
              <div className="row">
                <div className="col-md-9">
                  <div className="input-outer">
                    <label htmlFor="cat_name">Catalogue Title*</label>
                    <input type="text" className="input-wrapper custom-input" id="cat_name" name="name" value={formData.name} onChange={formHandler} />
                  </div>
                </div>
              </div>
              <div className="row">
              <div className="col-md-6">
              <div className="input-outer">
              <FileUploadPDF label="Upload Catalogue PDF" value={formData.image && `uploads/catalogue/${formData.image}`} onChange={fileVal} />
            </div>
            </div>
            </div>
             
            </form>
          </div>
          {catInfo?.catData?.id &&
          <div className="d-flex gap-2 mt-3">
            <NavLink className="delete_link" onClick={deleteIInfo}>Delete Catalogue?</NavLink>
            
          </div>
          }
        </div>
        <div className="col-md-3  pe-0">
          <div className="right_sidebar">
            <div className="form_submit">
              <button type="submit" className="button-primary"  onClick={formSubmit}>
              {catInfo?.catData?.id ? 'Update' : 'Publish Now'} 
              </button>
            </div>

           
          </div>
        </div>
      </div>
    </>
  );
};

export default CatalogueForm;

export async function fetchCatalogueInfo({ params }){
  const { catId } = params;

  let return_data = await store.dispatch(getCatalogueInfo({catId}));
  const catData = return_data.payload.category;

  if(catId){
    return (
      {
        catData:catData,
      }
    );
  }else{
    return (
      { }
    );
  }
  
}