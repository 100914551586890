import { createSlice } from '@reduxjs/toolkit';
import { getAllStateData } from './stateAction';

const initialState = {
	loading: false,
	state_details: null,
	filterText: {},
	error: null,
};

const stateSlice = createSlice({
	name: 'state',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getAllStateData.pending, (state) => {
				state.loading = true;
			})
			.addCase(getAllStateData.fulfilled, (state, action) => {
				state.loading = false;
				state.state_details = action.payload.states;
				state.error = null;
			})
			.addCase(getAllStateData.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload.error;
			});
	},
});

export default stateSlice.reducer;