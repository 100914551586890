import { createSlice } from '@reduxjs/toolkit';
import { getAllUserData, getAllUserInfo, updateUserInfo } from './userAction';

const initialState = {
	loading: false,
	user: null,
	user_details: null,
	filterText: {},
	error: null,
};

const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getAllUserData.pending, (state) => {
				state.loading = true;
			})
			.addCase(getAllUserData.fulfilled, (state, action) => {
				state.loading = false;
				state.user = action.payload.user;
				state.error = null;
			})
			.addCase(getAllUserData.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload.error;
			})
			.addCase(getAllUserInfo.pending, (state) => {
				state.loading = true;
			})
			.addCase(getAllUserInfo.fulfilled, (state, action) => {
				state.loading = false;
				state.user_details = action.payload.user;
				state.error = null;
			})
			.addCase(getAllUserInfo.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload.error;
			})
			.addCase(updateUserInfo.pending, (state) => {
				state.loading = true;
			})
			.addCase(updateUserInfo.fulfilled, (state, action) => {
				state.loading = false;
				state.user_details = action.payload.user;
				state.error = null;
			})
			.addCase(updateUserInfo.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload.error;
			});
	},
});

export default userSlice.reducer;

// export const {} = userSlice.actions;