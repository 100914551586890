import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosConfig from '../../utils/axiosConfig';

export const StoreNotificationInfo = createAsyncThunk('add/notification', async ({ formData }, { rejectWithValue }) => {
	try {
		const response = await axiosConfig.post('api/notification', formData);
		return await response.data;
	} catch (error) {
		return rejectWithValue({ error: error?.response?.data?.error, message: error?.response?.data?.message || error?.error?.message });
	}
});