import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosConfig from '../../utils/axiosConfig';

export const getAllUserData = createAsyncThunk('get/user', async (_, { rejectWithValue }) => {
	try {
		const response = await axiosConfig.get('api/user');
		return await response.data;
	} catch (error) {
		return rejectWithValue({ error: error?.response?.data?.error, message: error?.response?.data?.message || error?.error?.message });
	}
});
export const getAllUserInfo = createAsyncThunk('user_info', async ({ user_id }, { rejectWithValue }) => {
	try {
		const response = await axiosConfig.get('api/user/'+user_id);
		return await response.data;
	} catch (error) {
		return rejectWithValue({ error: error?.response?.data?.error, message: error?.response?.data?.message || error?.error?.message });
	}
});
export const updateUserInfo = createAsyncThunk('update_user_info', async ({ user_id, formVal  }, { rejectWithValue }) => {
	try {
		const response = await axiosConfig.patch('api/user/'+user_id, formVal);
		return await response.data;
	} catch (error) {
		return rejectWithValue({ error: error?.response?.data?.error, message: error?.response?.data?.message || error?.error?.message });
	}
});

export const uersDelete = createAsyncThunk('delete_user', async ({ user_id, formVal  }, { rejectWithValue }) => {
	try {
		const response = await axiosConfig.delete('api/user/'+user_id, formVal);
		return await response.data;
	} catch (error) {
		return rejectWithValue({ error: error?.response?.data?.error, message: error?.response?.data?.message || error?.error?.message });
	}
});


export const ImportUserInfo = createAsyncThunk('import/user', async ({ formVal }, { rejectWithValue }) => {
	try {		
		const response = await axiosConfig.post('api/user/import', formVal);
		return await response.data;
	} catch (error) {
		return rejectWithValue({ error: error?.response?.data?.error, message: error?.response?.data?.message || error?.error?.message });
	}
});