import React from 'react'
// import { Logo } from '../../assets/images/icons'

const Loading = () => {
    return (
        <div className='loading-logo'>
            Page Loading...
            {/* <Logo height="300" width="300px" /> */}
        </div>
    )
}

export default Loading