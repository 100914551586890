import React, { useState } from "react";
import { useLoaderData } from "react-router-dom";
import { getAllCatData } from "../../store/category/categoryAction.js";
import { useDispatch } from "react-redux";
import store from "../../store/index.jsx";
import { getAllProductData } from "../../store/product/productAction.js";
import Select2Wrapper from "../../components/controls/Select2Wrapper.jsx";
import { promiseToast } from "../../utils/Toast.jsx";
import { StoreNotificationInfo } from "../../store/notification/notificationAction.js";
import SingleDropdown from "../../components/controls/SingleDropdown.jsx";

const NotificationForm = () => {
  const dataReturn = useLoaderData();

  const categoryList = dataReturn?.catData?.map((data) => {
    return (
      {
        id:data.id,
        text:data.name,
      }
    )
  });  

  const productList = dataReturn?.prodData?.map((data) => {
    return (
      {
        id:data.id,
        text:data.title,
      }
    )
  });
  
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    title:'',
    type:'',
    description:'',
    refId:'',
  });
  
  const formHandler = (event) => {
    setFormData((prev) => ({
        ...prev,
        [event.target.name]: event.target.value
    }));
  };

  const handleSelectChange = (value) => {    
      setFormData((prev) => ({
        ...prev,
        ['refId']: value
      }));
  };

  const formSubmit = async () => {
    if(formData.type === "other"){
      formData.refId = "";
    }    
      await promiseToast(dispatch(StoreNotificationInfo({ formData  })), "Adding Notification Info...","Notification Data Added Successfully!");
  };

  return (
    <>
      <div className="page-title">
        <h2>Add Notification</h2>
      </div>
      <div className="row form_space">
        <div className="col-md-9 ps-0">
          <div className="form_bg custom_height">
            <form action="">
              <div className="row">
                <div className="col-md">
                  <div className="input-outer">
                    <label htmlFor="notification_name">Title*</label>
                    <input type="text" className="input-wrapper custom-input" id="notification_name" name="title" value={formData.title} onChange={formHandler} />
                  </div>
                </div>
                <div className="col-md">
                  <div className="input-outer">
                            <label htmlFor="notification_type">Type*</label>
                            <select id="notification_type" name="type" onChange={formHandler} value={formData.type}>
                              <option value="">Select Type</option>
                              <option value="product">Product</option>
                              <option value="category">Category</option>
                              <option value="other">Other</option>
                            </select>
                        </div>
                </div>
                { formData.type === "category" &&
                  <div className="col-md">
                    <div className="input-outer">
                              <label htmlFor="category">Category</label>
                              <SingleDropdown
                                options={categoryList}
                                multipleSelect={false}
                                placeholder="Select Category"
                                onChange={handleSelectChange}
                            />
                          </div>
                  </div>
                }
                { formData.type === "product" &&
                  <div className="col-md">
                    <div className="input-outer">
                              <label htmlFor="product">Product</label>
                              <SingleDropdown
                                options={productList}
                                placeholder="Select Product"
                                onChange={handleSelectChange}
                            />
                          </div>
                  </div>
                }
                <div className="col-md-12">
                  <div className="input-outer">
                    <label htmlFor="description">Description*</label>
                      <textarea
                        name="description"
                        id="description"
                        className="textarea_field"
                        rows="3"
                        cols="3"
                        onChange={formHandler}
                      >{formData.description}</textarea>
                  </div>
                </div>
              </div>
              
            </form>
          </div>
        </div>
        <div className="col-md-3  pe-0">
          <div className="right_sidebar">
            <div className="form_submit">
              <button type="submit" className="button-primary"  onClick={formSubmit}>
              {dataReturn?.catData?.id ? 'Update' : 'Publish Now'} 
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotificationForm;

export async function fetchNotificationInfo(){
  let catData, prodData;
  let { category , product } = await store.getState();

  if(category?.category_data === null){
    let return_cat_data = await store.dispatch(getAllCatData());
    catData = return_cat_data.payload.category;
  }else{
    catData = category.category_data;
  }

  if(product?.product_details === null){
    let return_state_data = await store.dispatch(getAllProductData());
    prodData = return_state_data.payload.products;
  }else{
    prodData = product.product_details;
  }

  return (
    {
      catData:catData,
      prodData:prodData,
    }
  );
}