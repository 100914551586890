import React, { useState } from "react";
import {  DataTableComponent } from '../../components/index.jsx';
import { NavLink, useLoaderData } from "react-router-dom";
import EditIcon from "../../assets/images/icons/EditIcon.jsx";
import store from "../../store/index.jsx";
import moment from "moment";
import { getAllQuotationData, getQuotationInfo } from "../../store/quotation/quotationAction.js";
import Pdf from "../../assets/images/icons/Pdf.jsx";
import QuotationPDF from "../../layout/pdf/QuotationPdf.jsx";
import { saveAs } from 'file-saver';
import { pdf, PDFViewer } from '@react-pdf/renderer';
import { useDispatch } from "react-redux";
import { useEffect } from "react";

const QuotationListing = () => {
  const dispatch = useDispatch();
  const quotationRecords = useLoaderData();

  const [leadTableData, setLeadTableData] = useState(quotationRecords.quotations);
  
  const [searchText, setSearchText] = useState('');

  const filteredData = leadTableData.filter(item => {
    const matchesSearchText = Object.values(item).some(value =>
      value !== null && value !== undefined &&
      value.toString().toLowerCase().includes(searchText.toLowerCase())
    );
    return matchesSearchText;
  });

  const downloadPdf = async (id) => {
    const res = await dispatch(getQuotationInfo({ quotation_id : id }));
    const quotationInfo = res.payload.quotation;
    
    const blob = await pdf(<QuotationPDF pdfData={quotationInfo} />).toBlob();
    saveAs(blob, 'Quotation.pdf');
  };

  const columns = [
    {
      key: "id",
      name: 'Id',
      cell: row => row.id,
      width: "5%",
    },
    {
      key: "username",
      name: 'Name',
      cell: row => <><p>{ row.username }</p><p>{ row.companyName }</p></>,
      // width: "17%",
    },
    {
      key: "contact_detail",
      name: 'Contact Details',
      cell: row => <><p>{ row.number }</p><p>{ row.email }</p></>,
      // width: "17%",
    },
    {
      key: "address",
      name: 'Address',
      cell: row => <><p>{ row.state }</p><p>{ row.city } - { row.pincode }</p></>,
      width: "12%",
    },
    {
      key: "totalQty",
      name: 'Quantity',
      cell: row => row.totalQty,
      width: "7%",
    },
    {
      key: "your_cost",
      name: 'Price',
      cell: row => <>&#x20b9; { row.your_cost.toFixed(2) }</>,
      width: "9%",
    },
    {
      key: "selling_amount",
      name: 'Selling Price',
      cell: row => <>&#x20b9; { row.selling_amount.toFixed(2) }</>,
      width: "9%",
    },
    {
      key: "createdAt",
      name: 'Created On',
      cell: (row) => moment(row.createdAt).format('DD MMM, YYYY hh:mm A'),
      width: "16%",
    },
    {
      key: "action",
      name: 'Action',
      cell: row => {
        return (
          <>
            {    
            <span className="cursor-pointer" onClick={() => downloadPdf(row.id)}>
              <Pdf height={15} width={15} />
            </span>        
            }
          </>
        )
      },
      width: "5%",
    }
  ];

  // const [dummyData, setDummyData] = useState([]);
  // useEffect(() => {
  //   const fetchData = async() => {
  //     const res = await dispatch(getQuotationInfo({ quotation_id : 8 }));
  //     const quotationInfo = res.payload.quotation;
  //     setDummyData(quotationInfo);
  //   }
  //   fetchData();
  // },[])
    
  return (
    <>
      <div className="page-title">
        <h2>All Quotation</h2>
      </div>
      {/* { dummyData && <PDFViewer width="100%" height="800px" >
        <QuotationPDF pdfData={dummyData} />
      </PDFViewer> } */}
      
      <div className="row listing_wrapper">
        <div className="col-md-3">
            <input
              type="search"
              className="table_search"
              placeholder="Search Anything.."
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
        </div>
        </div>
        <div className="table_wrapper mt-3">
            <DataTableComponent
                columns={columns}
                data={filteredData}
            />

        </div>
    </>
  );
};

export default QuotationListing;


export async function fetchQuotationData() {
  let return_data = await store.dispatch(getAllQuotationData());
  const quotation = return_data.payload;
  return quotation;
}