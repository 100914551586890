import React from 'react'
const PrimaryButton = ({ children, height, type, width, background, textTransform, padding,
  customStyle = {},
  ...props }) => {
  const buttonStyle = {
    width: width || 'fit-content',
    height: height || '40px',
    padding: padding || '0 12px',
    textTransform: textTransform || 'uppercase',
    backgroundColor: background || 'transparent'
  }
  return (
    <button type={type || 'button'} style={{ ...buttonStyle, ...customStyle }} className='button-primary' aria-label="primary-button" {...props}>{children}</button>
  )
}

export default PrimaryButton