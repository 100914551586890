import { createSlice } from '@reduxjs/toolkit';
import { DelProductInfo, getAllProductData, getProductInfo, ImportProdInfo, ModifyProductInfo, StoreProductInfo } from './productAction';

const initialState = {
	loading: false,
	product_details: null,
	filterText: {},
	error: null,
};

const productSlice = createSlice({
	name: 'product',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getAllProductData.pending, (state) => {
				state.loading = true;
			})
			.addCase(getAllProductData.fulfilled, (state, action) => {
				state.loading = false;
				state.product_details = action.payload.products;
				state.error = null;
			})
			.addCase(getAllProductData.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload.error;
			})
			.addCase(StoreProductInfo.pending, (state) => {
				state.loading = true;
			})
			.addCase(StoreProductInfo.fulfilled, (state, action) => {
				state.loading = false;
				state.error = null;
			})
			.addCase(StoreProductInfo.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload.error;
			})
			.addCase(getProductInfo.pending, (state) => {
				state.loading = true;
			})
			.addCase(getProductInfo.fulfilled, (state, action) => {
				state.loading = false;
				state.error = null;
			})
			.addCase(getProductInfo.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload.error;
			})
			.addCase(ModifyProductInfo.pending, (state) => {
				state.loading = true;
			})
			.addCase(ModifyProductInfo.fulfilled, (state, action) => {
				state.loading = false;
				state.error = null;
			})
			.addCase(ModifyProductInfo.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload.error;
			})
			.addCase(DelProductInfo.pending, (state) => {
				state.loading = true;
			})
			.addCase(DelProductInfo.fulfilled, (state, action) => {
				state.loading = false;
				state.error = null;
			})
			.addCase(DelProductInfo.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload.error;
			})
			.addCase(ImportProdInfo.pending, (state) => {
				state.loading = true;
			})
			.addCase(ImportProdInfo.fulfilled, (state, action) => {
				state.loading = false;
				state.error = null;
			})
			.addCase(ImportProdInfo.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload.error;
			});
	},
});

export default productSlice.reducer;