import React, { useState } from "react";
import DatePicker from "react-multi-date-picker";
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import moment from "moment";
import { getDashboardData } from "../../store/dashboard/dashboardAction";
import store from "../../store";
import { useLoaderData } from "react-router-dom";
import { useDispatch } from "react-redux";
import { promiseToast } from "../../utils/Toast";

ChartJS.register(ArcElement, Tooltip, Legend);


const Dashboard = () => {
	const ApiRecords = useLoaderData();
	const dispatch = useDispatch();

	const getCurrentWeekRange = () => {
		const startOfWeek = moment().startOf('week').format('YYYY-MM-DD');
    	const endOfWeek = moment().endOf('week').format('YYYY-MM-DD');
		return [startOfWeek, endOfWeek];
	};
	
	const [defaultWeekRange, setDefaultWeekRange] = useState(getCurrentWeekRange);
	const [dashboardData, setDashboardData] = useState({
		cancel_count: ApiRecords?.cancel_count || 0,
		confirm_count: ApiRecords?.confirm_count || 0,
		dispatch_count: ApiRecords?.dispatch_count || 0,
		placed_count: ApiRecords?.placed_count || 0,
	});
	
	const data = {
		labels: ['Cancelled', 'Confirmed', 'Dispatched', 'Placed', 'Blank'],
		datasets: [
		  {
			label: 'Order Distribution',
			data: [dashboardData?.cancel_count, dashboardData?.confirm_count, dashboardData?.dispatch_count, dashboardData?.placed_count, 1],
			backgroundColor: ['#d77748', '#27d6f2', '#44b678', '#FFCE56', '#ebf8ff'],
			hoverBackgroundColor: ['#d77748', '#27d6f2', '#44b678', '#FFCE56', '#ebf8ff'],
		  },
		],
	  };

	const [filterData, setFilterData] = useState({
		start_date: null,
		end_date: null,
	});

	const handleDateChange = async (dates) => {
		setFilterData(prev => ({
		  ...prev,
		  start_date: dates[0]?.format('YYYY-MM-DD'),
		  end_date: dates[1]?.format('YYYY-MM-DD'),
		}));

		setDefaultWeekRange([dates[0]?.format('YYYY-MM-DD'), dates[1]?.format('YYYY-MM-DD')]);

		let start_date = dates[0]?.format('YYYY-MM-DD');
		let end_date = dates[1]?.format('YYYY-MM-DD');
		if(start_date && end_date){
			const res = await dispatch(getDashboardData({ start_date, end_date }));
			setDashboardData(res?.payload?.dashboard);
		}
	};


  return (
    <>
      <div className="page-title">
        <h2>Dashboard</h2>
      </div>

      <div className="row listing_wrapper dashboard_wrapper">
        <div className="col-md-3"></div>
        <div className="col-md-9 mb-4">
          <div className="filter-section daterange_custom">
			<DatePicker
					weekDays={["S", "M", "T", "W", "T", "F", "S"]}
					editable={false}
					id={'cal'}
					format="YYYY-MM-DD"
					range
					rangeHover
					value={defaultWeekRange}
					onChange={handleDateChange}
					aria-label="calendar"
				/>
          </div>
        </div>

		<div className="col-md-3">
			<div className="bg-color">
				<h5 className="mb-2 w-100">Order Placed</h5>
				<p className="mb-0"> { dashboardData?.placed_count }</p>
			</div>
		</div>
		<div className="col-md-3">
			<div className="bg-color">
				<h5 className="mb-2 w-100">Order Confirmed</h5>
				<p className="mb-0">{ dashboardData?.confirm_count }</p>
			</div>
		</div>
		<div className="col-md-3">
			<div className="bg-color">
				<h5 className="mb-2 w-100">Order Dispatched</h5>
				<p className="mb-0">{ dashboardData?.dispatch_count }</p>
			</div>
		</div>
		<div className="col-md-3">
			<div className="bg-color">
				<h5 className="mb-2 w-100">Order Cancelled</h5>
				<p className="mb-0">{ dashboardData?.cancel_count }</p>
			</div>
		</div>
		<div className="col-md-4 mt-4">
			<Pie data={data} />
		</div>
      </div>
    </>
  );
};

export default Dashboard;

export async function fetchDesktopData() {
	const start_date = moment().startOf('week').format('YYYY-MM-DD');
    const end_date = moment().endOf('week').format('YYYY-MM-DD');

	let return_data = await store.dispatch(getDashboardData({ start_date, end_date }));
	const dashboard = return_data?.payload?.dashboard || '';
	return dashboard;
  }