import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosConfig from '../../utils/axiosConfig';

export const getAllStateData = createAsyncThunk('get_state', async (_, { rejectWithValue }) => {
	try {
		const response = await axiosConfig.get('api/getData/states');
		return await response.data;
	} catch (error) {
		return rejectWithValue({ error: error?.response?.data?.error, message: error?.response?.data?.message || error?.error?.message });
	}
});

export const getDistrictData = createAsyncThunk('get_district', async (stateId, { rejectWithValue }) => {
    try {
        const response = await axiosConfig.get(`api/getData/districtList`, {
            params: { stateId }, // Send as query parameters
        });
        return await response.data;
    } catch (error) {
        return rejectWithValue({ error: error?.response?.data?.error, message: error?.response?.data?.message || error?.error?.message });
    }
});
