import React, { useCallback, useRef, useState } from "react";
import {
  Calendar,
  DataTableComponent,
  PrimaryButton,
  Reset,
} from "../../components";
import CsvIcon from "../../assets/images/icons/CsvIcon.jsx";
import { NavLink, useLoaderData } from "react-router-dom";
import EditIcon from "../../assets/images/icons/EditIcon.jsx";
import Valid from "../../assets/images/icons/Valid.jsx";
import InValid from "../../assets/images/icons/InValid.jsx";
import { getAllUserData,uersDelete,ImportUserInfo } from "../../store/user/userAction.js";
import moment from 'moment';
import store from "../../store/index.jsx";
import { CSVLink } from 'react-csv';
import { formatNumberWithCommas } from "../../utils/commonHelpers.js";
import Swal from "sweetalert2";
import DeleteIcon from "../../assets/images/icons/DeleteIcon.jsx";
import { utils, writeFile } from "xlsx";
import { promiseToast } from "../../utils/Toast.jsx";
import { useDispatch } from "react-redux";

const UserListing = () => {
  const userRecords = useLoaderData();
  const selectRef = useRef(null);
  const [userData, setUserData] = useState(userRecords.user);
  const [searchText, setSearchText] = useState('');

  const dispatch = useDispatch();
  const ref = useRef(null)
 
  const [filterData, setFilterData] = useState({
    type_val: "",
    special_val: "",
    status_val: "",
    start_date: null,  
        end_date: null,  
  });

  const handleReset = useCallback(
    (e) => {
      let initialValues = {
        type_val: "",      // Reset type
        special_val: "",   // Reset special
        status_val: "",    // Reset status
        start_date: null,  
        end_date: null,  
      };
      setFilterData(initialValues);
      selectRef?.current?.clearValue();
    },
    [setFilterData]
  );

  const handleDateChange = (dates) => {
    setFilterData(prev => ({
      ...prev,
      start_date: dates[0]?.format('YYYY-MM-DD'),
      end_date: dates[1]?.format('YYYY-MM-DD'),
    }));
  };

  const filterHandler = (event) => {
    setFilterData((prev) => ({
      ...prev,
      [event.target.name]: event.target.value
    }));
};

const deleteUser = async (userId) => {
  Swal.fire({
    text: "Are you sure you want to delete this user?",
    showCancelButton: true,
    confirmButtonColor: '#006bcc',
    cancelButtonColor: '#bc3535',
    confirmButtonText: 'Yes, delete it!',
    cancelButtonText: 'Cancel',
  }).then(async (result) => {
    if (result.isConfirmed) {
      try {
        
        await store.dispatch(uersDelete({ user_id: userId })); // Call your delete user action
        Swal.fire('Deleted!', 'User has been deleted.', 'success');

        // Filter out the deleted user from the current list
        setUserData(prevData => prevData.filter(user => user.id !== userId));
      } catch (error) {
        Swal.fire('Error!', 'Something went wrong while deleting the user.', 'error');
      }
    }
  });
};

const renderTierType = (tierType) => {
  switch (tierType) {
    case 'tier1':
      return 'Regular';
    case 'tier2':
      return 'Pune City';
    case 'tier3':
      return 'Tier 3';
    case 'tier4':
      return 'Tier 4';
    case 'tier5':
      return 'Tier 5';
    default:
      return '-';
  }
};

  const columns = [
    {
      key: "uid",
      name: "UID",
      cell: (row) => row.id,
      width: "5%",
    },
    {
      key: "name",
      name: "Name",
      cell: (row) => <><p>{row.companyName}</p><p>{row.username}</p></>,
    },
    {
      key: "contact_detail",
      name: "Contact Details",
      cell: (row) => <><p>{row.number}</p><p>{row.email}</p></>,
    },
    {
      key: "address",
      name: "Address",
      cell: (row) => <><p>{row.state}</p><p>{row.city} - {row.pincode}</p></>,
      width: "11%",
    },
   
    {
      key: "value",
      name: "Value",
      cell: (row) => <><p>{row?.ordersCount} Order</p><p>price - {formatNumberWithCommas(row?.totalAmount) || 0}</p></>,
      width: "10%",
    },
    {
      key: "type",
      name: "Type",
      cell: (row) => <><p>{ renderTierType(row.tierType) || '-'}</p></>,
      width: "9%",
    },
    {
      key: "device_type",
      name: "Device Type",
      cell: (row) => <><p>{row.device_type}</p><p>{row.device_type}</p></>,
      width: "11%",
    },
    {
      key: "loginStatus",
      name: "loginStatus",
      cell: (row) => (
        <p className="status_text">
          {row.loginStatus === true ? "Login" : "Logout"}
        </p>
      ),
      width: "6%",
    },
    {
      key: "special",
      name: "Special",
      cell: (row) => <><p className="valid_icon">{row.isSpecial === true ? <Valid /> :  <InValid />}</p></>,
      width: "6%",
    },
    {
      key: "updated_at",
      name: "Updated On",
      cell: (row) => moment(row.updatedAt).format('DD MMM, YYYY hh:mm A'),
      width: "16%",
    },
    {
      key: "status",
      name: "Status",
      cell: (row) => (
        <>
          {row.isUnderReview === 1 ? (
            <p className="status_action pending_status">Pending</p>
          ) : row.isUnderReview === 2 ? (
            <p className="status_action cancelled_status">Rejected</p>
          ) : (
            <p className="status_action publish_status">Approved</p>
          )}
        </>
      ),
      width: "10%",
    },
    {
      key: "action",
      name: "Action",
      cell: (row) => {
        return (
          <>
            {
              <NavLink
                to={`/users/edit/${row.id}`}
                className="action-icon"
                aria-label="edit"
              >
                {" "}
                <EditIcon height={15} width={15} />
              </NavLink>
            },
            {
            //   <DeleteIcon
            //   className="action-icon delete-icon"
            //   onClick={() => deleteUser(row.id)} // Call deleteUser with the user ID
            //   aria-label="delete"
            // >
              <DeleteIcon
  className="action-icon delete-icon"
  onClick={() => deleteUser(row.id)} // Call deleteUser with the user ID
  aria-label="delete"
/>
            }
          </>
        );
      },
      width: "5%",
    },
  ];

  const filteredData = userData.filter(item => {
    const matchesSearchText = Object.values(item).some(value =>
      value !== null && value !== undefined &&
      value.toString().toLowerCase().includes(searchText.toLowerCase())
    );

    const matchesType = filterData.type_val === "" || item.tierType === filterData.type_val;
    const device_type = filterData.device_type === "" || item.device_type === filterData.device_type;
    const matchesSpecial = filterData.special_val === "" || (item.isSpecial ? "yes" : "no") === filterData.special_val;
    const matchesStatus = filterData.status_val === "" || (item.isUnderReview === 1 ? "0" : "1") === filterData.status_val;

    const matchesDate = (!filterData.start_date || moment(item.updatedAt).isSameOrAfter(filterData.start_date)) &&
                        (!filterData.end_date || moment(item.updatedAt).isSameOrBefore(filterData.end_date));

    return matchesSearchText && matchesType && matchesSpecial && matchesStatus && matchesDate;
  });


  const csvData = userData.map((item) =>{
    const { StateId, deletedAt, createdAt, updatedAt, ...itemWithoutStateId } = item;
    return itemWithoutStateId;
  });

  // Sample Xlsx file
  const Edata = [
    ["UserName", "MobileNumber", "Email", "CompanyName", "Address", "Role", "City", "Pincode", "StateId", "districtId"]
  ];

  const exportToExcel = () => {
      const worksheet = utils.aoa_to_sheet(Edata);
      const workbook = utils.book_new();
      utils.book_append_sheet(workbook, worksheet, "Sheet1");
      writeFile(workbook, "Users_sample.xlsx");
    };


    const [excelFile, setExcelFile] = useState(null);

    const handleImageChange = (event) => {
      let file;
      file = event.target.files[0];
      if (file) {
          const reader = new FileReader();
          reader.onloadend = () => {
          };
          reader.readAsDataURL(file);
          setExcelFile(file);
      }
      
    };
  
    
  const submitform = async () => {
    let formVal = new FormData();
    formVal.append("file", excelFile);

    await promiseToast(
      dispatch(ImportUserInfo({ formVal })),
      "Uploading...",
      "Users Imported Successfully!"
    );
    setExcelFile(null);
    ref.current.click();
    window.location.reload();
    // let return_data = await store.dispatch(getAllUserData());
    // const filteredData = return_data.payload;
  };


  return (
    <>
      <div className="page-title">
        <h2>All Users</h2>
      </div>
      <div className="row listing_wrapper">
        <div className="col-md-3">
          <input
            type="search"
            className="table_search"
            placeholder="Search Anything.."
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>
        <div className="col-md-9">
          <div className="filter-section">
            <div>
              <select name="type_val" id="" className="field_dropdown" value={filterData.type_val} onChange={filterHandler}>
                <option value="">Select Type</option>
                <option value="tier1">Regular</option>
                <option value="tier2">Pune City</option>
                <option value="tier3">Tier 3</option>
                <option value="tier4">Tier 4</option>
                <option value="tier5">Tier 5</option>
              </select>
            </div>
            <div>
              <select name="special_val" id="special_val" className="field_dropdown" value={filterData.special_val} onChange={filterHandler}>
                <option value="">Select Special</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
            </div>
            <div>
              <select name="status_val" id="status_val" className="field_dropdown" value={filterData.status_val} onChange={filterHandler}>
                <option value="">Select Status</option>
                <option value="0">Pending</option>
                <option value="1">Approved</option>
              </select>
            </div>

            <Calendar
              inputRef={selectRef}
              format="YYYY-MM-DD"
              range
              rangeHover
              onChange={handleDateChange}
            />
            <Reset height="30px" padding="0 6px" onClick={handleReset} />
            <div className="action-btn">
              <PrimaryButton
                  textTransform={"capitalize"}
                  background={"#fff"}
                  height={"30px"}
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  Import
                </PrimaryButton>
                <div
                  className="modal fade"
                  id="exampleModal"
                  tabindex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-md modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-header p-0 mb-4">
                        <h4 className="modal-title" id="exampleModalLabel">
                          Import Product
                        </h4>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          ref={ref}
                        ></button>
                      </div>
                      <div className="modal-body p-0">
                        <form action="" >
                          <div className="w-50 m-auto">
                            <input type="file" className="file_field" name="" id="" 
                            onChange={handleImageChange} 
                            />
                          </div>

                          <div className="col-md-12 text-center mt-3">
                            <button
                              type="button"
                              className="edit_button  me-3"
                              onClick={submitform}
                            >
                              Submit
                            </button>
                            <button className="edit_button text-decoration-none" 
                            onClick={exportToExcel}
                            >Download XLSX</button>
                             
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            <div className="download-sec">
              <button type="button" className="icon-wrapper">
              <CSVLink
                data={csvData}
                filename="user_data.csv"
                target="_blank"
              >
                <CsvIcon height={20} width={20} />
              </CSVLink>
                
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="table_wrapper mt-3">
        <DataTableComponent
          columns={columns}
          data={filteredData}
          pagination
        />
      </div>
    </>
  );
}; 

export default UserListing;


export async function fetchUserData() {
  // let { user} = await store.getState();
  // if(user.user === null){
    let return_data = await store.dispatch(getAllUserData());
    const user = return_data.payload;
  // }
  return user;
}