// import { NotFound } from "../../pages";
import { useAccessControl } from "../hooks/useAccessControl";

const HasAccess = ({
    children,
    permissions,
    renderAuthFailed,
    role
}) => {
    const { checkRoleAccess } = useAccessControl();
    
    if (permissions === 'dashboard') return children;

    const hasRequiredPermission = checkRoleAccess(role || [], permissions ? [permissions] : []);
    
    if (hasRequiredPermission) return children;
    return renderAuthFailed;
    // return renderAuthFailed || <NotFound />;
};

export default HasAccess;
